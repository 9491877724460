import { Typography, Box as MuiBox, Button } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <MuiBox component="div" textAlign="center">
      <Typography variant="body1">
        そんざいしないようです。
        <br />
        あみだくじが見つかりません。
      </Typography>
      <MuiBox m={2} component="div" />
      <Button href="/" variant="contained" color="primary">
        <Typography variant="body1">ホームに戻る</Typography>
      </Button>
    </MuiBox>
  );
};

export default NotFound;
