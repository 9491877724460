import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UserState } from "../../domain/types/User";
import { getMyUser } from "../../domain/features/UserSlice";
import Body from "../components/HomePage/Body";
import { isMaintenance } from "../../application/config/env";

const HomePage = () => {
  const userState = useSelector((state: { user: UserState }) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyUser() as any);
  }, [dispatch]);

  return (
    <>
      <Body userState={userState} isMaintenance={isMaintenance} />
    </>
  );
};

export default HomePage;
