import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#ffad80', // マイルドなオレンジ
        },
        secondary: {
            main: '#009999', // ティールブルー
        },
        background: {
            default: '#f5f5f5', // 背景色を少し落ち着かせたグレー
            paper: '#ffffff', // コンポーネント用の背景色を白に
        },
        text: {
            primary: '#333333', // テキスト色を暗めのグレーに
            secondary: '#666666', // サブテキスト用のグレー
        },
        action: {
            active: '#ffad80', // アクティブな要素にマイルドなオレンジ
            hover: '#ffd1a3', // ホバー時の柔らかいオレンジ
        },
        warning: {
            main: '#b0b0b0', // グレー系のマイルドな色
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: [
            'Roboto', // Roboto フォント
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        button: {
            textTransform: 'none',
            fontWeight: 600,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#f5f5f5', // 背景色を少し落ち着かせたグレー
                    color: '#333333', // テキスト色を暗めのグレーに
                    '& a': {
                        color: '#ffad80', // リンクの色
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    backgroundColor: '#ffad80',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)', // 柔らかく自然な影
                    '&:hover': {
                        backgroundColor: '#ffd1a3',
                        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // ホバー時に少し強めの影
                    },
                    margin: '8px 0',
                },
                containedSecondary: {
                    color: '#ffffff',
                    backgroundColor: '#009999', // セカンダリーカラーの背景色
                    '&:hover': {
                        backgroundColor: '#007a7a', // ホバー時のセカンダリーカラーの背景色
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff', // ヘッダーの背景色を白に
                    color: '#333333', // ヘッダーのテキスト色を暗めのグレーに
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // 軽い影
                    borderBottom: '1px solid #e0e0e0', // ボーダーを淡いグレーに
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff', // ペーパーの背景色を白に
                    color: '#333333', // テキスト色を暗めのグレーに
                    borderRadius: '8px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)', // 柔らかく自然な影
                    padding: '16px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#333333', // テキスト色を暗めのグレーに
                    margin: '8px 0',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        backgroundColor: '#ffffff', // 入力フィールドの背景色を白に
                        color: '#333333', // 入力フィールドのテキスト色を暗めのグレーに
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ffad80', // 枠線の色をオレンジに
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ffd1a3', // ホバー時の枠線の色を柔らかいオレンジに
                    },
                    '& .MuiInputLabel-root': {
                        color: '#666666', // ラベルの色をグレーに
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#ffffff', // ダイアログの背景色を白に
                    borderRadius: '12px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)', // 柔らかく自然な影
                    padding: '16px',
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: '#333333', // ダイアログコンテンツのテキスト色を暗めのグレーに
                },
            },
        },
    },
});

export default lightTheme;
