import React, { useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { CatmullRomCurve3, Vector3, WebGLRenderer } from "three";
import { Environment, Text, OrbitControls } from "@react-three/drei";
import { Box as MuiBox } from "@mui/material";
import { Player } from "../../../domain/types/Player";
import { Direction } from "../../../domain/types/Bridge";
import Fireworks from "./Fireworks";
import { Fab } from "@mui/material"; // 追加
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"; // 追加

type Props = {
  playersSortedByStartIndex: Player[];
  myPlayerIndex: number;
  playerLabels: string[];
  playersSortedByGoalIndex: Player[];
};

const AmidaResult = (props: Props) => {
  const {
    playersSortedByStartIndex,
    myPlayerIndex,
    playerLabels,
    // playersSortedByGoalIndex,
  } = props;

  const Rig = ({ v = new Vector3() }) => {
    const isAnimating = useRef(true); // アニメーション中フラグ
    const start = useRef(Date.now());

    return useFrame((state) => {
      if (isAnimating.current) {
        state.camera.position.lerp(v.set(0, 0, 1), 0.03);
        // カメラが目標位置に近づいたらアニメーションを停止
        // 途中で操作されて、目標位置に微妙にたどりつけない可能性があるので、時間でも false にする
        if (
          state.camera.position.distanceTo(v.set(0, 0, 1)) < 0.01 ||
          Date.now() - start.current > 1900
        ) {
          isAnimating.current = false; // アニメーションを終了
        }
      }
    });
  };

  const tubularSegments = 20;
  // 線の太さ
  const radius = 0.02;
  const radialSegments = 8;

  // fontSize を計算する関数を追加
  const calculateFontSize = (text: string) => {
    if (text.length <= 3) {
      return 0.15;
    } else if (text.length <= 7) {
      return 0.15;
    } else {
      return 0.1;
    }
  };

  // 文字列を指定した文字数ごとに分割する関数
  const splitText = (text: string, maxLength: number) => {
    const result = [];
    for (let i = 0; i < text.length; i += maxLength) {
      if (result.length > 2) {
        result.push("...");
        break;
      }
      result.push(text.substring(i, i + maxLength));
    }
    return result;
  };

  //グラデーションの色
  // const palyerColors = ["#0047AB", "#20B2AA", "#B0E0E6", "#AFEEEE", "#87CEEB"];
  const getPlayerColor = (playerIndex: number) => {
    return "#1B263B";
  };
  const getHorizontalBridgeColor = (
    playerIndex: number,
    playerNum: number,
    direction: Direction
  ) => {
    switch (direction) {
      case Direction.Left:
        if (playerIndex === 0) {
          return "white";
        }
        return getPlayerColor(playerIndex);
      case Direction.Right:
        if (playerIndex === playerNum - 1) {
          return "white";
        }
        return getPlayerColor(playerIndex);
      default:
        return "white";
    }
  };

  const renderer = useRef<WebGLRenderer | null>(null);

  // 端はみ出た横棒を透明にするためのアニメーション
  const [opacity, setOpacity] = useState(1);
  useEffect(() => {
    let start = Date.now();
    const duration = 3000; // 3秒間かけて透明にする

    const animate = () => {
      const now = Date.now();
      const elapsed = now - start;
      const progress = Math.min(elapsed / duration, 1);
      setOpacity(1 - progress);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    const timer = setTimeout(() => {
      requestAnimationFrame(animate);
    }, 2000); // 2秒後にアニメーションを開始

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    return () => {
      // コンポーネントがアンマウントされたときに WebGL コンテキストを解放する
      if (renderer.current) {
        renderer.current.dispose();
        renderer.current = null;
      }
    };
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  return (
    <MuiBox
      component="div"
      height={500}
      style={{
        userSelect: "none",
        WebkitUserSelect: "none",
        position: "relative",
      }}
    >
      <Canvas
        camera={{ position: [0, 0, 10] }}
        onCreated={({ gl }) => {
          // レンダラーを保存しておく
          renderer.current = gl;
        }}
      >
        <Environment
          files={["px.png", "nx.png", "py.png", "ny.png", "pz.png", "nz.png"]}
          path="/images/"
          background
        />

        <ambientLight intensity={1} />
        <pointLight position={[0, 0, 0]} />

        <OrbitControls
          enableZoom={true} // ズームを有効にする
          enablePan={true} // パンを有効にする
          enableRotate={true} // 回転を有効にする
          //スクロールを無視する
          onWheel={(e) => e.nativeEvent.preventDefault()}
        />

        <Rig />

        <Fireworks />

        {playersSortedByStartIndex.map((player, index) => {
          const bridges = player.bridges;
          const depth = playersSortedByStartIndex.length * -0.7 + 0.5;
          const gap = (index - (playersSortedByStartIndex.length - 1) / 2) * 1;
          const vertexVertical = [gap, -1, depth, gap, 1, depth];
          const veticalPoints: Vector3[] = [];
          for (let i = 0; i < vertexVertical.length; i += 3) {
            veticalPoints.push(
              new Vector3(
                vertexVertical[i],
                vertexVertical[i + 1],
                vertexVertical[i + 2]
              )
            );
          }
          const verticalPath = new CatmullRomCurve3(veticalPoints, true);

          return (
            <React.Fragment key={index + "player"}>
              {/* 上の文字（プレイヤー名）を表示 */}
              {splitText(player.displayName?.val ?? "", 7).map(
                (line, lineIndex) => (
                  <Text
                    key={lineIndex}
                    position={[gap, 1 - lineIndex * 0.12, depth + 0.2]}
                    color={getPlayerColor(index)}
                    fontSize={calculateFontSize(player.displayName?.val ?? "")}
                    maxWidth={10}
                    lineHeight={1}
                    letterSpacing={0.02}
                    textAlign={"center"}
                    font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
                    anchorX="center"
                    anchorY="middle"
                    outlineColor={
                      // 自分のプレイヤーの場合は色を変える
                      myPlayerIndex === index ? "#ff6b6b" : "#ffffff"
                    }
                    outlineWidth={0.02}
                  >
                    {line}
                  </Text>
                )
              )}
              {/* 下の文字（プレイヤーのラベル、役割り）を表示 */}
              {splitText(playerLabels[index], 7).map((line, lineIndex) => (
                <Text
                  key={lineIndex}
                  position={[gap, lineIndex * -0.12 - 1, depth + 0.2]} // Y位置を調整してテキストを重ねない
                  color={"#333333"}
                  outlineColor={
                    myPlayerIndex !== undefined &&
                    playersSortedByStartIndex[myPlayerIndex]?.ball
                      ?.goalIndex === index
                      ? "#ff6b6b"
                      : "#ffffff"
                  }
                  outlineWidth={0.02}
                  fontSize={calculateFontSize(playerLabels[index])}
                  maxWidth={10}
                  lineHeight={1}
                  letterSpacing={0.02}
                  textAlign={"center"}
                  font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
                  anchorX="center"
                  anchorY="middle"
                >
                  {line}
                </Text>
              ))}
              {/* 下の文字(プレイヤーの名前) を表示 */}
              {/* {splitText(
                playersSortedByGoalIndex[index].displayName?.val ?? "",
                7
              ).map((line, lineIndex) => (
                <Text
                  key={lineIndex}
                  position={[
                    gap,
                    -1 -
                      calculateFontSize(playerLabels[index]) *
                        (playerLabels[index].length / 7 !== 0
                          ? playerLabels[index].length / 7 + 1
                          : 2) -
                      lineIndex * 0.12,
                    depth + 0.2,
                  ]}
                  color={"#333333"}
                  outlineColor={"#ffffff"}
                  outlineWidth={0.02}
                  fontSize={
                    calculateFontSize(player.displayName?.val ?? "") - 0.03
                  }
                  maxWidth={10}
                  lineHeight={1}
                  letterSpacing={0.02}
                  textAlign={"center"}
                  font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
                  anchorX="center"
                  anchorY="middle"
                >
                  {line}
                </Text>
              ))} */}

              {/* 縦棒を表示 */}
              <mesh>
                <tubeGeometry
                  args={[
                    verticalPath,
                    tubularSegments,
                    radius,
                    radialSegments,
                    true,
                  ]}
                />
                <meshStandardMaterial color={getPlayerColor(index)} />
              </mesh>

              {/* 横棒を表示 */}
              {bridges.map((bridge) => {
                var height = bridge.relativeHeight;
                if (height === undefined) {
                  return null;
                }
                height = (height - 0.5) * 2;
                const from = new Vector3(
                  bridge.direction === Direction.Left ? -1 + gap : 1 + gap,
                  height,
                  depth
                );
                const to = new Vector3(gap, height, depth);
                return (
                  <mesh key={bridge.id}>
                    <tubeGeometry
                      args={[
                        new CatmullRomCurve3([from, to], true),
                        tubularSegments,
                        radius,
                        radialSegments,
                        true,
                      ]}
                    />
                    <meshStandardMaterial
                      color={getHorizontalBridgeColor(
                        index,
                        playersSortedByStartIndex.length,
                        bridge.direction ?? Direction.Undefined
                      )}
                      // white の場合は透明度を設定
                      transparent={true}
                      opacity={
                        getHorizontalBridgeColor(
                          index,
                          playersSortedByStartIndex.length,
                          bridge.direction ?? Direction.Undefined
                        ) === "white"
                          ? opacity
                          : 1
                      }
                    />
                  </mesh>
                );
              })}
            </React.Fragment>
          );
        })}
      </Canvas>
      <Fab
        color="secondary"
        onClick={scrollToBottom}
        style={{
          position: "absolute",
          bottom: 20,
          right: 20,
          zIndex: 1000,
          opacity: 0.7, // 半透明に設定
        }}
      >
        <ArrowDownwardIcon />
      </Fab>
    </MuiBox>
  );
};

export default AmidaResult;
