import { useEffect } from "react";

// useInterval フックの定義
const useInterval = (callback: () => void, delay: number | null) => {
    useEffect(() => {
        if (delay === null) return; // delayがnullの場合は何もしない
        if (delay <= 0) return; // delayが0以下の場合は何もしない

        const interval = setInterval(callback, delay); // コールバックを指定した間隔で実行
        return () => clearInterval(interval); // クリーンアップ
    }, [callback, delay]);
}

export default useInterval