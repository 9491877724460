import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "../../domain/types/User";
import { getMyUser } from "../../domain/features/UserSlice";
import { getEvent } from "../../domain/features/EventSlice";
import { AmidaEventState } from "../../domain/types/Event";
import Body from "../components/EditPage/Body";
import { useParams } from "react-router-dom";
import Maintenance from "../common/Maintenance";
import { isMaintenance } from "../../application/config/env";

const EditPage = () => {
  const userState = useSelector((state: { user: UserState }) => state.user);
  const amidaEventState = useSelector(
    (state: { event: AmidaEventState }) => state.event
  );
  const dispatch = useDispatch();
  const { eventId } = useParams<{ eventId: string }>();

  useEffect(() => {
    if (!eventId) {
      return;
    }
    dispatch(getMyUser() as any);
    dispatch(getEvent(eventId) as any);
  }, [dispatch, eventId]);

  return (
    <>
      {isMaintenance ? (
        <Maintenance />
      ) : (
        <Body {...{ amidaEventState, userState }} />
      )}
    </>
  );
};

export default EditPage;
