import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UserState } from "../../domain/types/User";
import { getUser } from "../../domain/features/UserSlice";

import Body from "../components/UserPage/Body";
import { useParams } from "react-router-dom";
import { isMaintenance } from "../../application/config/env";
import Maintenance from "../common/Maintenance";

const UserPage = () => {
  const userState = useSelector((state: { user: UserState }) => state.user);
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();

  useEffect(() => {
    if (userId == null) {
      return;
    }
    dispatch(getUser(userId) as any);
  }, [dispatch, userId]);

  return (
    <>
      {isMaintenance ? (
        <Maintenance />
      ) : (
        <>
          <Body userState={userState} />
        </>
      )}
    </>
  );
};

export default UserPage;
