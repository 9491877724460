import React, { useEffect, useState } from "react";
import { User } from "../../../domain/types/User";
import { useDispatch } from "react-redux";
import {
  getAdminEvents,
  getJoinedEvents,
} from "../../../domain/features/UserSlice";
import EventList from "./EventList";
import { CircularProgress, Box as MuiBox } from "@mui/material";

type bodyProps = {
  user: User;
};

const Success = (props: bodyProps) => {
  const { user } = props;
  const dispatch = useDispatch();
  const [loadingAdmin, setLoadingAdmin] = useState(true);
  const [loadingJoin, setLoadingJoin] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user.id == null) {
        return;
      }
      await dispatch(getAdminEvents(user.id) as any);
      setLoadingAdmin(false);
    };
    fetchData();
  }, [dispatch, user.id]);

  useEffect(() => {
    const fetchData = async () => {
      if (user.id == null) {
        return;
      }
      await dispatch(getJoinedEvents(user.id) as any);
      setLoadingJoin(false);
    };
    fetchData();
  }, [dispatch, user.id]);

  if (loadingAdmin || loadingJoin) {
    return (
      <MuiBox component="div" textAlign="center">
        <CircularProgress />
      </MuiBox>
    );
  }

  return (
    <>
      <div>id: {user.id}</div>
      <MuiBox component="div" m={1} />
      <EventList
        adminEvents={user.adminEvents ? user.adminEvents : []}
        joinedEvents={user.joinedEvents ? user.joinedEvents : []}
      />
    </>
  );
};

export default Success;
