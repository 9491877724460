import { Box as MuiBox, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UserState } from "../../domain/types/User";
import { getMyUser } from "../../domain/features/UserSlice";

const Footer = () => {
  const userState = useSelector((state: { user: UserState }) => state.user);
  const user = userState.user;

  const dispatch = useDispatch();

  useEffect(() => {
    if (user.id) {
      return;
    }
    dispatch(getMyUser() as any);
  }, [dispatch, user.id]);

  return (
    <>
      <MuiBox
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
        }}
      >
        <Container maxWidth="sm">
          <MuiBox
            component="div"
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(2, auto)",
              sm: "repeat(4, auto)",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Link
              to={user.id ? `/u/${user.id}` : "/u/not-found"}
              style={{ textDecoration: "none", margin: "0rem 1rem" }}
            >
              <Typography variant="body2" color="textSecondary" noWrap>
                最近のあみだくじ
              </Typography>
            </Link>
            <Link
              to="/faq"
              style={{ textDecoration: "none", margin: "0rem 1rem" }}
            >
              <Typography variant="body2" color="textSecondary" noWrap>
                よくある質問
              </Typography>
            </Link>
            <Link
              to="/terms"
              style={{ textDecoration: "none", margin: "0rem 1rem" }}
            >
              <Typography variant="body2" color="textSecondary" noWrap>
                利用規約
              </Typography>
            </Link>
            <Link
              to="/privacy"
              style={{ textDecoration: "none", margin: "0rem 1rem" }}
            >
              <Typography variant="body2" color="textSecondary" noWrap>
                プライバシーポリシー
              </Typography>
            </Link>
          </MuiBox>

          <MuiBox m={3} component="div" />
          <Typography
            variant="body1"
            color="textSecondary"
            noWrap
            align="center"
          >
            © 2024 あみださん
          </Typography>
        </Container>
      </MuiBox>
    </>
  );
};

export default Footer;
