import React from "react";
import { Typography, Button, Box as MuiBox, Paper } from "@mui/material";
import { UserState } from "../../../domain/types/User";
import EventForm from "../../common/EventForm";
import useHomePageBody from "../../hooks/useHomePageBody";
import ScrollToTop from "../../common/ScrollToTop";

type bodyProps = {
  userState: UserState;
  isMaintenance: boolean;
};

const Body = (props: bodyProps) => {
  const userState = props.userState;
  const user = userState.user;
  const isMaintenance = props.isMaintenance;

  const {
    createEvent,
    eventTitle,
    setEventTitle,
    bridgeNumRangeMin,
    setBridgeNumRangeMin,
    bridgeNumRangeMax,
    setBridgeNumRangeMax,
    playerLabelWithErrors,
    maxJoinedUsersNum,
    setMaxJoinedUsersNum,
    setPlayerLabelWithErrors,
  } = useHomePageBody(user);

  return (
    <>
      <ScrollToTop />
      <Paper>
        <EventForm
          setEventTitle={setEventTitle}
          setBridgeNumRangeMin={setBridgeNumRangeMin}
          setBridgeNumRangeMax={setBridgeNumRangeMax}
          setPlayerLabelWithErrors={setPlayerLabelWithErrors}
          eventTitle={eventTitle}
          bridgeNumRangeMin={bridgeNumRangeMin}
          bridgeNumRangeMax={bridgeNumRangeMax}
          playerLabelWithErrors={playerLabelWithErrors}
          maxJoinedUsersNum={maxJoinedUsersNum}
          setMaxJoinedUsersNum={setMaxJoinedUsersNum}
        />

        <MuiBox m={2} component="div" />

        <MuiBox
          component="div"
          sx={{
            filter: !isMaintenance ? "none" : "grayscale(100%) opacity(0.5)",
            pointerEvents: !isMaintenance ? "auto" : "none",
          }}
          width="100%"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => createEvent()}
            fullWidth
          >
            <Typography variant="body1">あみだくじをつくる</Typography>
          </Button>
        </MuiBox>

        {isMaintenance && (
          <>
            <MuiBox m={2} component="div">
              <Typography variant="body1">現在メンテナンス中です。</Typography>
            </MuiBox>
          </>
        )}
      </Paper>

      <MuiBox m={3} component="div" />

      <Paper>
        <Typography variant="h6">あみださん</Typography>
        <Typography variant="body1">
          参加型のあみだくじサイトです。
          参加者の棒をつないで、あみだくじを完成させます。
          <br />
          あみださんを使って、みんなで楽しく抽選・役割り分担をしましょう！
        </Typography>
        <img
          src="/images/amidasan.png"
          alt="あみださん"
          style={{ width: "100%" }}
        />

        <Typography variant="h6">はじめかた</Typography>
        <Typography variant="body1">
          1. あみだくじ名を入力
          <br />
          2. 最大参加人数を設定
          <br />
          3. 振り分けを追加（デフォルトでは数字で振り分け）
          <br />
          4. 「あみだくじをつくる」ボタンを押す
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <Typography variant="body1">上に戻る</Typography>
        </Button>
      </Paper>
    </>
  );
};

export default Body;
