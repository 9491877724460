export interface Bridge {
    id: string | undefined;
    playerUuid: string | undefined;
    direction: Direction | undefined;
    relativeHeight: number | undefined;
}

export enum Direction {
    Left = "left",
    Right = "right",
    Undefined = "undefined",
}
