import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

type Props = {
  bridgeNumRangeMax: number;
};

const BridgePreview = (props: Props) => {
  const { bridgeNumRangeMax } = props;
  const width = 500;
  const height = 200;
  const playerNum = 4;
  const fontSize = 13;
  // const iconSize = 15;
  // const iconMargin = 2;

  //横の余白
  const marginWidth = width / playerNum / 2;
  // 縦の余白
  const marginHeight = height / 11;

  const strokeWidth = 5;

  const [vAnimationClass, setVAnimationClass] = useState(
    "vertial-line-animation"
  );
  const [hAnimationClass, setHAnimationClass] = useState(
    "horizontal-line-animation"
  );
  // 参加者ごとのアニメーション遅延時間
  const animationDelay = (index: number) => `${index * 1000}ms`;

  //グラデーションの色
  const palyerColors = ["#0047AB", "#20B2AA", "#B0E0E6", "#AFEEEE", "#87CEEB"];

  const getPlayerColor = (playerIndex: number) => {
    return palyerColors[playerIndex % palyerColors.length];
  };

  //最初の右と左の橋のランダムシード
  const [randomSeedsForMin2Bridges, setRandomSeedsForMin2Bridges] = useState<
    number[]
  >(Array.from({ length: 2 * playerNum }, () => 0.5));
  //追加の橋のランダムシード
  const [randomSeedsForAdditionalBridges, setRandomSeedsForAdditionalBridges] =
    useState<number[]>(
      Array.from({ length: (bridgeNumRangeMax - 2) * playerNum }, () => 0.5)
    );
  //追加の橋が右か左かのランダムシード
  const [
    randomSeedsForAdditionalBridgesROrL,
    setRandomSeedsForAdditionalBridgesROrL,
  ] = useState<boolean[]>(
    Array.from({ length: (bridgeNumRangeMax - 2) * playerNum }, () => true)
  );

  useEffect(() => {
    // ランダムシードを更新する
    setRandomSeedsForMin2Bridges(
      Array.from({ length: 2 * playerNum }, () => Math.random())
    );
    setRandomSeedsForAdditionalBridges(
      Array.from({ length: (bridgeNumRangeMax - 2) * playerNum }, () =>
        Math.random()
      )
    );
    setRandomSeedsForAdditionalBridgesROrL(
      Array.from(
        { length: (bridgeNumRangeMax - 2) * playerNum },
        () => Math.random() < 0.5
      )
    );

    // 縦の棒のアニメーションを先に実行
    const t1 = setTimeout(() => {
      setVAnimationClass("vertial-line-animation");
    }, 300);

    // 横の棒のアニメーションを遅延させる
    const t2 = setTimeout(() => {
      setHAnimationClass("horizontal-line-animation");
    }, 600);

    return () => {
      //timer をクリア
      clearTimeout(t1);
      clearTimeout(t2);
      setVAnimationClass("line-hidden");
      setHAnimationClass("line-hidden");
    };
  }, [bridgeNumRangeMax]);

  const leftBridge = (playerIndex: number) => {
    const y =
      randomSeedsForMin2Bridges[playerIndex * 2] * (height - marginHeight * 2) +
      marginHeight;
    //ランダムにしない方法も検討した
    // const y =
    //   (1 - (index + 1) * (1 / (playerNum + 1))) *
    //     (height - marginHeight * 2) +
    //   marginHeight +
    //   10;
    return (
      <line
        className={hAnimationClass}
        style={{ animationDelay: animationDelay(playerIndex) }}
        x1={(width / playerNum) * (playerIndex + 1) - marginWidth}
        y1={y}
        x2={(width / playerNum) * playerIndex - marginWidth}
        y2={y}
        stroke={
          // 左端の場合に色を薄くする
          playerIndex === 0 ? backgroundColor : getPlayerColor(playerIndex)
        }
        strokeWidth={strokeWidth}
      />
    );
  };

  const rightBridge = (playerIndex: number) => {
    const y =
      randomSeedsForMin2Bridges[playerIndex * 2 + 1] *
        (height - marginHeight * 2) +
      marginHeight;
    //ランダムにない方法も検討した
    // const y =
    //   (1 - (index + 1) * (1 / (playerNum + 1))) *
    //     (height - marginHeight * 2) +
    //   marginHeight +
    //    10;
    return (
      <line
        className={hAnimationClass}
        style={{ animationDelay: animationDelay(playerIndex) }}
        x1={(width / playerNum) * (playerIndex + 1) - marginWidth}
        y1={y}
        x2={(width / playerNum) * (playerIndex + 2) - marginWidth}
        y2={y}
        stroke={
          // 右端の場合に色を薄くする
          playerIndex === playerNum - 1
            ? backgroundColor
            : getPlayerColor(playerIndex)
        }
        strokeWidth={strokeWidth}
      />
    );
  };

  const theme = useTheme();
  const textColor = theme.palette.text.primary;
  const backgroundColor = theme.palette.background.default;

  return (
    <>
      <svg viewBox={`0 0 ${width} ${height}`}>
        {Array.from({ length: playerNum }).map((_, playerIndex) => {
          return (
            <React.Fragment key={playerIndex}>
              {/* 縦の棒 */}
              <text
                x={(width / playerNum) * (playerIndex + 1) - marginWidth}
                y={marginHeight - fontSize / 2}
                fontSize={fontSize}
                textAnchor="middle"
                fill={textColor}
              >
                参加者
              </text>
              {/* <image
                  // x={(width / playerNum) * (index + 1) - marginWidth - 10}
                  y={marginHeight - iconSize}
                  width={iconSize - iconMargin}
                  height={iconSize - iconMargin}
                  href="/images/person.png"
                  //center
                  x={
                    (width / playerNum) * (index + 1) -
                    marginWidth -
                    (iconSize - iconMargin) / 2
                  }
                ></image> */}
              <line
                x1={(width / playerNum) * (playerIndex + 1) - marginWidth}
                y1={marginHeight}
                x2={(width / playerNum) * (playerIndex + 1) - marginWidth}
                y2={height - marginHeight}
                stroke={getPlayerColor(playerIndex)}
                strokeWidth={strokeWidth * 1.7}
                className={vAnimationClass}
                style={{ animationDelay: animationDelay(playerIndex) }}
              />
              {/* に伸ばす横棒 */}
              {leftBridge(playerIndex)}
              {/* 右に伸ばす横棒 */}
              {rightBridge(playerIndex)}
              {
                // 縦の棒から bridgeNumRangeMax -2 本の横の棒を伸ばす
                Array.from({ length: bridgeNumRangeMax - 2 }).map(
                  (_, bridgeIndex) => {
                    const y = isNaN(
                      randomSeedsForAdditionalBridges[
                        bridgeIndex + playerIndex * (bridgeNumRangeMax - 2)
                      ]
                    )
                      ? marginHeight
                      : randomSeedsForAdditionalBridges[
                          bridgeIndex + playerIndex * (bridgeNumRangeMax - 2)
                        ] *
                          (height - marginHeight * 2) +
                        marginHeight;
                    if (
                      randomSeedsForAdditionalBridgesROrL[
                        bridgeIndex + playerIndex * (bridgeNumRangeMax - 2)
                      ]
                    ) {
                      // 左に伸ばす横棒
                      return (
                        <React.Fragment key={bridgeIndex}>
                          <line
                            className={hAnimationClass}
                            style={{
                              animationDelay: animationDelay(playerIndex),
                            }}
                            x1={
                              (width / playerNum) * (playerIndex + 1) -
                              marginWidth
                            }
                            y1={y}
                            x2={(width / playerNum) * playerIndex - marginWidth}
                            y2={y}
                            stroke={
                              // 左端の場合に色を薄くする
                              playerIndex === 0
                                ? backgroundColor
                                : getPlayerColor(playerIndex)
                            }
                            strokeWidth={strokeWidth}
                          />
                        </React.Fragment>
                      );
                    } else {
                      // 右に伸ばす横棒
                      return (
                        <React.Fragment key={bridgeIndex}>
                          <line
                            className={hAnimationClass}
                            style={{
                              animationDelay: animationDelay(playerIndex),
                            }}
                            x1={
                              (width / playerNum) * (playerIndex + 1) -
                              marginWidth
                            }
                            y1={y}
                            x2={
                              (width / playerNum) * (playerIndex + 2) -
                              marginWidth
                            }
                            y2={y}
                            stroke={
                              // 右端の場合に色を薄くする
                              playerIndex === playerNum - 1
                                ? backgroundColor
                                : getPlayerColor(playerIndex)
                            }
                            strokeWidth={strokeWidth}
                          />
                        </React.Fragment>
                      );
                    }
                  }
                )
              }
            </React.Fragment>
          );
        })}
      </svg>
      <style>
        {`
            .vertial-line-animation {
              stroke-dasharray: ${
                height - marginHeight
              }; /* 線の長さに応じて調整 */
              stroke-dashoffset: ${height - marginHeight};
              animation: draw 300ms forwards; /* アニメーションの時間を調整 */
            }
            .horizontal-line-animation {
              stroke-dasharray: ${width / playerNum}; /* 線の長さに応じて調整 */
              stroke-dashoffset: ${width / playerNum};
              animation: draw 300ms forwards; /* アニメーションの時間を調整 */
            }

            .line-hidden {
              display: none;
              animation: none;
            }

            @keyframes draw {
              to {
                stroke-dashoffset: 0;
              }
            }

          `}
      </style>
    </>
  );
};

export default BridgePreview;
