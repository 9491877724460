import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AmidaEvent, AmidaEventState } from "../types/Event";
import { apiUrl } from "../../application/config/env";
import { FetchStatus } from "../types/FetchStatus";
import { Bridge } from "../types/Bridge";


export const createEvent = createAsyncThunk<{ event: AmidaEvent, timestamp: string }, { title: string, adminUserId: string, bridgeNumRangeMin: number, bridgeNumRangeMax: number, playerLabels: string[], isBlockEndWithoutAdmin: boolean, isBlockUpdateWithoutAdmin: boolean, maxJoinedUsersNum: number }>(
    "events/",
    async ({ title, adminUserId, bridgeNumRangeMin, bridgeNumRangeMax, playerLabels, isBlockEndWithoutAdmin, isBlockUpdateWithoutAdmin, maxJoinedUsersNum }, { rejectWithValue }) => {
        try {
            const data = {
                "title": title,
                "adminUserId": adminUserId,
                "bridgeNumRangeMin": bridgeNumRangeMin,
                "bridgeNumRangeMax": bridgeNumRangeMax,
                "playerLabels": playerLabels,
                "maxJoinedUsersNum": maxJoinedUsersNum,
                "isBlockEndWithoutAdmin": isBlockEndWithoutAdmin,
                "isBlockUpdateWithoutAdmin": isBlockUpdateWithoutAdmin
            };
            //cookie と dataを送信
            const response = await axios.post(`${apiUrl}/events`, data, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getEvent = createAsyncThunk<{ event: AmidaEvent, timestamp: string }, string>(
    "events/:id",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/events/${id}`, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateEvent = createAsyncThunk<{ event: AmidaEvent, timestamp: string }, { eventId: string, adminUserId: string, title: string, bridgeNumRangeMin: number, bridgeNumRangeMax: number, playerLabels: string[], maxJoinedUsersNum: number, password: string }>(
    "events/update",
    async ({ eventId, adminUserId, title, bridgeNumRangeMin, bridgeNumRangeMax, playerLabels, maxJoinedUsersNum, password }, { rejectWithValue }) => {
        try {
            const data = {
                "title": title,
                "adminUserId": adminUserId,
                "bridgeNumRangeMin": bridgeNumRangeMin,
                "bridgeNumRangeMax": bridgeNumRangeMax,
                "playerLabels": playerLabels,
                "maxJoinedUsersNum": maxJoinedUsersNum,
                "password": password,
            };
            const response = await axios.put(`${apiUrl}/events/${eventId}`, data, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const endEvent = createAsyncThunk<{ event: AmidaEvent, timestamp: string }, { eventId: string, adminUserId: string, password: string }>(
    "events/end",
    async ({ eventId, adminUserId, password }, { rejectWithValue }) => {
        const data = {
            "adminUserId": adminUserId,
            "password": password,
        }
        try {
            const response = await axios.patch(`${apiUrl}/events/${eventId}/end`, data, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const lockUpdateEndEvent = createAsyncThunk<{ event: AmidaEvent, timestamp: string, password: string }, { eventId: string, userId: string }>(
    "events/lock_update_end",
    async ({ eventId, userId }, { rejectWithValue }) => {
        try {
            const data = {
                "userId": userId,
            };
            const response = await axios.patch(`${apiUrl}/events/${eventId}/lock_update_end`, data, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const unlockUpdateEndEvent = createAsyncThunk<{ event: AmidaEvent, timestamp: string }, { eventId: string, password: string }>(
    "events/unlock_update_end",
    async ({ eventId, password }, { rejectWithValue }) => {
        try {
            const data = {
                "password": password,
            };
            const response = await axios.patch(`${apiUrl}/events/${eventId}/unlock_update_end`, data, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const unlockUpdateEndEventByAdminUser = createAsyncThunk<{ event: AmidaEvent, timestamp: string }, { eventId: string, adminUserId: string }>(
    "events/unlock_update_end_by_admin_user",
    async ({ eventId, adminUserId }, { rejectWithValue }) => {
        try {
            const data = {
                "adminUserId": adminUserId,
            };
            const response = await axios.patch(`${apiUrl}/events/${eventId}/unlock_update_end_by_admin_user`, data, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const joinEvent = createAsyncThunk<{ event: AmidaEvent, timestamp: string }, { eventId: string, userId: string, displayName: string, bridges: Bridge[] }>(
    "events/join",
    async ({ eventId, userId, displayName, bridges }, { rejectWithValue }) => {
        try {
            const data = {
                "displayName": displayName,
                "userId": userId,
                "bridges": bridges,
            };
            const response = await axios.post(`${apiUrl}/events/${eventId}/joined_users`, data, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateJoinedUser = createAsyncThunk<{ event: AmidaEvent, timestamp: string }, { eventId: string, userId: string, displayName: string, bridges: Bridge[] }>(
    "events/update_joined_user",
    async ({ eventId, userId, displayName, bridges }, { rejectWithValue }) => {
        try {
            const data = {
                "displayName": displayName,
                "userId": userId,
                "bridges": bridges,
            };
            const response = await axios.put(`${apiUrl}/events/${eventId}/joined_users/${userId}`, data, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const leaveEvent = createAsyncThunk<{ event: AmidaEvent, timestamp: string }, { eventId: string, userId: string }>(
    "events/leave",
    async ({ eventId, userId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${apiUrl}/events/${eventId}/joined_users/${userId}`, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    status: FetchStatus.IDOL,
    amidaEvent: {
        id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        title: undefined,
        rank: undefined,
        status: undefined,
        startTime: undefined,
        endTime: undefined,
        bridgeNumRange: undefined,
        adminUsers: [],
        joinedUsers: [],
        players: [],
        playerLabels: [],
        maxJoinedUsersNum: undefined,
        isBlockEndWithoutAdmin: false,
        isBlockUpdateWithoutAdmin: false,
        lockUpdateEnd: undefined,
    } as AmidaEvent,
    timestamp: "",
    isEditing: false,
} as AmidaEventState;

const eventSlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        foo: (state, action) => {
            console.log(action.payload);
        },
        enableEditing: (state) => {
            state.isEditing = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createEvent.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.amidaEvent = action.payload.event;
            state.timestamp = action.payload.timestamp;
            state.isEditing = false;
        });
        builder.addCase(createEvent.rejected, (state, action) => {
            console.log(action.error.message);
        });
        builder.addCase(getEvent.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.amidaEvent = action.payload.event;
            state.timestamp = action.payload.timestamp;
            state.isEditing = false;
        });
        builder.addCase(getEvent.rejected, (state, action) => {
            state.status = FetchStatus.FAILED;
            // console.log(action.error.message);
        });

        builder.addCase(updateEvent.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.amidaEvent = action.payload.event;
            state.timestamp = action.payload.timestamp;
            state.isEditing = false;
        });
        builder.addCase(updateEvent.rejected, (state, action) => {
            // console.log(action.error.message);
        });

        builder.addCase(endEvent.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.amidaEvent = action.payload.event;
            state.timestamp = action.payload.timestamp;
            state.isEditing = false;
        });

        builder.addCase(endEvent.rejected, (state, action) => {
            // console.log(action.error.message);
        });

        builder.addCase(lockUpdateEndEvent.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.amidaEvent = action.payload.event;
            state.timestamp = action.payload.timestamp;
            state.isEditing = false;
        });
        builder.addCase(lockUpdateEndEvent.rejected, (state, action) => {
            // console.log(action.error.message);
        });

        builder.addCase(unlockUpdateEndEvent.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.amidaEvent = action.payload.event;
            state.timestamp = action.payload.timestamp;
            state.isEditing = false;
        });
        builder.addCase(unlockUpdateEndEvent.rejected, (state, action) => {
            // console.log(action.error.message);
        });

        builder.addCase(unlockUpdateEndEventByAdminUser.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.amidaEvent = action.payload.event;
            state.timestamp = action.payload.timestamp;
            state.isEditing = false;
        });

        builder.addCase(unlockUpdateEndEventByAdminUser.rejected, (state, action) => {
            // console.log(action.error.message);
        });

        builder.addCase(joinEvent.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.amidaEvent = action.payload.event;
            state.timestamp = action.payload.timestamp;
            state.isEditing = false;
        });
        builder.addCase(joinEvent.rejected, (state, action) => {
            // console.log(action.error.message);
        });
        builder.addCase(updateJoinedUser.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.amidaEvent = action.payload.event;
            state.timestamp = action.payload.timestamp;
            state.isEditing = false;
        }
        );
        builder.addCase(updateJoinedUser.rejected, (state, action) => {
            // console.log(action.error.message);
        });
        builder.addCase(leaveEvent.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.amidaEvent = action.payload.event;
            state.timestamp = action.payload.timestamp;
            state.isEditing = false;
        }
        );
        builder.addCase(leaveEvent.rejected, (state, action) => {
            // console.log(action.error.message);
        });
    },
});


export const { foo, enableEditing } = eventSlice.actions;
export default eventSlice.reducer;
