import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box as MuiBox,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <AppBar position="static">
        <Container maxWidth="md">
          <Toolbar>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              <Typography
                variant="h6"
                style={{
                  textShadow: "1px 1px 2px black",
                  fontWeight: "bold",
                  width: "150px",
                }}
              >
                <span
                  style={{
                    color: "#ff6b6b",
                    fontWeight: "bold",
                    fontSize: "25px",
                    fontFamily: "Zen Maru Gothic, serif",
                  }}
                >
                  あ
                </span>
                <span style={{ color: "#A4C8F0" }}>み</span>
                <span style={{ color: "#A4C8F0" }}>だ</span>
                {/* <span style={{ color: "#B6E880" }}>だ</span> */}
                <span style={{ color: "#b2bec3" }}>さん</span>
              </Typography>
            </Link>
            <MuiBox component="div" m={1} />
            <Typography
              variant="subtitle2"
              style={{
                wordBreak: "keep-all",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%", // 必要に応じて調整
              }}
            >
              参加型 あみだくじ ランダム 抽選
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <div style={{ marginBottom: "10px" }} />
    </>
  );
};

export default Header;
