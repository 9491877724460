import { Paper } from "@mui/material";
import React from "react";
import ScrollToTop from "../common/ScrollToTop";
import JsonContent from "../common/JsonContent";

const TermsPage = () => {
  return (
    <>
      <ScrollToTop />

      <Paper>
        <JsonContent jsonFilePath="/data/terms.json" />
      </Paper>
    </>
  );
};

export default TermsPage;
