import React, { useEffect, useRef, useState } from "react";
import { Bridge, Direction } from "../../../domain/types/Bridge";
import { Environment } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";
import {
  Button,
  IconButton,
  Box as MuiBox,
  Tooltip,
  Typography,
  Slider,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { AmidaEvent } from "../../../domain/types/Event";
import { User } from "../../../domain/types/User";
import { generateUUID } from "three/src/math/MathUtils";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

type Props = {
  amidaEvent: AmidaEvent;
  user: User;
  myBridges: Bridge[];
  setMyBridges: (bridges: Bridge[]) => void;
  enableEditing: ActionCreatorWithoutPayload<"event/enableEditing">;
  selectedMyBridgeIndex: number | null;
  setSelectedMyBridgeIndex: (index: number | null) => void;
};

const AmidaInput = (props: Props) => {
  const {
    amidaEvent,
    user,
    myBridges,
    setMyBridges,
    enableEditing,
    selectedMyBridgeIndex,
    setSelectedMyBridgeIndex,
  } = props;

  const dispatch = useDispatch();

  const [otherBridgePairs, setOtherBridgePairs] = useState<Bridge[][]>([]);

  useEffect(() => {
    if (user.id === undefined) {
      return;
    }
    const myPlayer = amidaEvent.players.find((p) => p.userId === user.id);
    if (myPlayer === undefined) {
      return;
    }

    const bridges = myPlayer.bridges;
    setMyBridges(bridges);

    const otherPlayers = amidaEvent.players.filter((p) => p.userId !== user.id);
    const otherBridgePairs = otherPlayers.map((p) => p.bridges);
    setOtherBridgePairs(otherBridgePairs);
  }, [amidaEvent.players, setMyBridges, user.id]);

  const handleAddBridge = (direction: Direction) => {
    if (amidaEvent.bridgeNumRange === undefined) {
      return;
    }
    if (myBridges.length >= amidaEvent.bridgeNumRange.max) {
      alert("これ以上追加できません");
      return;
    }
    const rondomRelativeHeight = Math.random() * 0.98 + 0.01;
    setMyBridges([
      ...myBridges,
      {
        id: generateUUID(),
        playerUuid: undefined,
        direction: direction,
        relativeHeight: rondomRelativeHeight,
      },
    ]);
    setSelectedMyBridgeIndex(myBridges.length);
  };

  // const [color, setColor] = useState<string>("hotpink");

  // const Rig = ({ v = new Vector3() }) => {
  //   return useFrame((state) => {
  //     state.camera.position.lerp(
  //       v.set(state.mouse.x / 5, state.mouse.y / 5, 2),
  //       0.03
  //     );
  //   });
  // };

  const tubularSegments = 20;
  // 線の太さ
  const radius = 0.05;
  const radialSegments = 8;

  // あみだくじ縦棒の座標
  const vertexVertical = [0, -1, 0, 0, 1, 0];
  const veticalPoints: THREE.Vector3[] = [];
  for (let i = 0; i < vertexVertical.length; i += 3) {
    veticalPoints.push(
      new THREE.Vector3(
        vertexVertical[i],
        vertexVertical[i + 1],
        vertexVertical[i + 2]
      )
    );
  }
  const verticalPath = new THREE.CatmullRomCurve3(veticalPoints, true);

  const getAddBlidgeButtonColor = (direction: Direction) => {
    if (amidaEvent.bridgeNumRange === undefined) {
      return "primary";
    }
    if (myBridges.length < amidaEvent.bridgeNumRange.min - 1) {
      return "primary";
    }

    return myBridges.find((b) => b.direction === direction) === undefined
      ? "primary"
      : "secondary";
  };

  const getAddBlidgeButtonDisabled = (direction: Direction) => {
    if (amidaEvent.bridgeNumRange === undefined) {
      return true;
    }
    const isExitOtherDirection = myBridges.find(
      (b) => b.direction !== direction
    );
    if (
      myBridges.length >= amidaEvent.bridgeNumRange.max - 1 &&
      !isExitOtherDirection
    ) {
      return true;
    }
    if (myBridges.length >= amidaEvent.bridgeNumRange.max) {
      return true;
    }
    return false;
  };

  const getSlicerValue = () => {
    if (selectedMyBridgeIndex === null) {
      return 50;
    }
    if (
      myBridges[selectedMyBridgeIndex] === null ||
      myBridges[selectedMyBridgeIndex] === undefined
    ) {
      return 50;
    }
    const selectedMyBridge = myBridges[selectedMyBridgeIndex];
    if (selectedMyBridge.relativeHeight === undefined) {
      return 50;
    }
    return selectedMyBridge.relativeHeight * 100;
  };

  const renderer = useRef<THREE.WebGLRenderer | null>(null);
  useEffect(() => {
    return () => {
      // コンポーネントがアンマウントされたときに WebGL コンテキストを解放する
      if (renderer.current) {
        renderer.current.dispose();
        renderer.current = null;
      }
    };
  }, []);

  return (
    <MuiBox
      component="div"
      height={500}
      position="relative"
      style={{
        userSelect: "none", // テキスト選択を無効にする
        WebkitUserSelect: "none", // Safari用のプレフィックスも忘れずに
      }}
    >
      <Canvas
        camera={{ position: [0, 0.1, 2] }}
        onCreated={({ gl }) => {
          // レンダラーを保存しておく
          renderer.current = gl;
        }}
      >
        <Environment
          files={["px.png", "nx.png", "py.png", "ny.png", "pz.png", "nz.png"]}
          path="/images/"
          background
        />

        <ambientLight intensity={1} />
        <pointLight position={[0, 0, 0]} />

        <fog attach="fog" color={"black"} near={1} far={10} />
        {/* <Rig /> */}

        {/* 自分の縦棒 */}
        <mesh key={"my_vertical"}>
          <tubeGeometry
            args={[verticalPath, tubularSegments, radius, radialSegments, true]}
          />

          <meshToonMaterial color="white" />
        </mesh>

        {/* 自分の横棒 */}
        {myBridges.map((bridge, index) => {
          var height = bridge.relativeHeight;
          if (height === undefined) {
            return null;
          }
          height = (height - 0.5) * 2;
          const from = new THREE.Vector3(
            bridge.direction === Direction.Left ? -1 : 1,
            height,
            0
          );
          const to = new THREE.Vector3(0, height, 0);
          return (
            <mesh
              key={bridge.id}
              onClick={(e) => setSelectedMyBridgeIndex(index)}
            >
              <tubeGeometry
                args={[
                  new THREE.CatmullRomCurve3([from, to], true),
                  tubularSegments,
                  radius,
                  radialSegments,
                  true,
                ]}
              />
              <meshStandardMaterial
                color={index === selectedMyBridgeIndex ? "orange" : "white"}
              />
            </mesh>
          );
        })}

        {/* 他の人の棒 */}
        {otherBridgePairs.map((bridges, index) => {
          const depth = -4 * (index + 1);
          const gap = (index + 1) * 0.3;
          const vertexVertical = [gap, -1, depth, gap, 1, depth];
          const veticalPoints: THREE.Vector3[] = [];
          for (let i = 0; i < vertexVertical.length; i += 3) {
            veticalPoints.push(
              new THREE.Vector3(
                vertexVertical[i],
                vertexVertical[i + 1],
                vertexVertical[i + 2]
              )
            );
          }
          const verticalPath = new THREE.CatmullRomCurve3(veticalPoints, true);

          return (
            <React.Fragment key={index + "other"}>
              <mesh>
                <tubeGeometry
                  args={[
                    verticalPath,
                    tubularSegments,
                    radius,
                    radialSegments,
                    true,
                  ]}
                />
                <meshStandardMaterial color={"white"} />
              </mesh>
              {bridges.map((bridge) => {
                var height = bridge.relativeHeight;
                if (height === undefined) {
                  return null;
                }
                height = (height - 0.5) * 2;
                const from = new THREE.Vector3(
                  bridge.direction === Direction.Left ? -1 + gap : 1 + gap,
                  height,
                  depth
                );
                const to = new THREE.Vector3(gap, height, depth);
                return (
                  <mesh key={bridge.id}>
                    <tubeGeometry
                      args={[
                        new THREE.CatmullRomCurve3([from, to], true),
                        tubularSegments,
                        radius,
                        radialSegments,
                        true,
                      ]}
                    />
                    <meshStandardMaterial color={"white"} />
                  </mesh>
                );
              })}
            </React.Fragment>
          );
        })}
      </Canvas>
      <MuiBox
        component="div"
        position="absolute"
        top="0"
        width="100%"
        display="flex"
        justifyContent="space-around"
      >
        <Tooltip title="左に横棒を追加">
          <Button
            variant="contained"
            color={getAddBlidgeButtonColor(Direction.Left)}
            disabled={getAddBlidgeButtonDisabled(Direction.Left)}
            onClick={() => {
              handleAddBridge(Direction.Left);
              dispatch(enableEditing());
            }}
            sx={{ boxShadow: "none", margin: "10px" }}
          >
            <ArrowLeftIcon sx={{ color: "white" }} />
            <Typography variant="body1"> 左に追加</Typography>
          </Button>
        </Tooltip>
        <MuiBox component="div" m={1} />
        <Tooltip title="右に横棒を追加">
          <Button
            variant="contained"
            color={getAddBlidgeButtonColor(Direction.Right)}
            disabled={getAddBlidgeButtonDisabled(Direction.Right)}
            onClick={() => {
              handleAddBridge(Direction.Right);
              dispatch(enableEditing());
            }}
            sx={{ boxShadow: "none", margin: "10px" }}
          >
            <Typography variant="body1">右に追加</Typography>
            <ArrowRightIcon sx={{ color: "white" }} />
          </Button>
        </Tooltip>
        <MuiBox component="div" position="absolute" top="100px" height="300px">
          <Slider
            orientation="vertical"
            defaultValue={50}
            aria-label="vertical slider"
            disabled={selectedMyBridgeIndex === null}
            value={getSlicerValue()}
            max={99.9}
            min={0.1}
            onChange={(e: any, newValue: any) => {
              if (typeof newValue !== "number") {
                return;
              }
              if (selectedMyBridgeIndex === null) {
                return;
              }
              const newLocal: number = newValue / 100;
              const newBridges = myBridges.map((bridge, i) => {
                if (i === selectedMyBridgeIndex) {
                  return { ...bridge, relativeHeight: newLocal };
                }
                return bridge;
              });
              setMyBridges(newBridges);
              dispatch(enableEditing());
            }}
          />
        </MuiBox>

        <MuiBox
          component="div"
          position="absolute"
          top="420px"
          width="100%"
          display="flex"
          justifyContent="space-around"
        >
          <Typography variant="body2">
            {myBridges.length} / {amidaEvent.bridgeNumRange?.max} (
            {amidaEvent.bridgeNumRange?.min} 以上)
          </Typography>
        </MuiBox>

        <MuiBox
          component="div"
          position="absolute"
          top="400px"
          height="50px"
          width="50px"
          margin="30px"
          right="0"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          borderRadius="50%"
          bgcolor="rgba(255, 255, 255, 0.6)"
          boxShadow={3}
        >
          <IconButton
            color="secondary"
            aria-label="delete"
            onClick={() => {
              if (selectedMyBridgeIndex === null) {
                return;
              }
              setMyBridges(
                myBridges.filter((bridge, i) => i !== selectedMyBridgeIndex)
              );
              setSelectedMyBridgeIndex(null);
              dispatch(enableEditing());
            }}
            disabled={selectedMyBridgeIndex === null}
          >
            <Delete fontSize="large" />
          </IconButton>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default AmidaInput;
