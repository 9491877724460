import React, { useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEvent } from "../../domain/features/EventSlice";
import { AmidaEventState } from "../../domain/types/Event";
import Body from "../components/CreateCompletePage/Body";
import { isMaintenance } from "../../application/config/env";
import Maintenance from "../common/Maintenance";

const CreateCompletePage = () => {
  const dispatch = useDispatch();
  const amidaEventState: AmidaEventState = useSelector(
    (state: { event: AmidaEventState }) => state.event
  );

  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const eventId = urlParams.get("event_id");
    if (!eventId) {
      return;
    }
    dispatch(getEvent(eventId) as any);
    // async function myInit(eventId: string) {
    //   try {
    //     const resGetEvent = await dispatch(getEvent(eventId) as any);
    //     const resEvent: AmidaEvent = resGetEvent.payload.event;
    //     if (resEvent.id !== eventId) navigate("/");
    //   } catch (e) {
    //     navigate("/");
    //   }
    // }
    // myInit(eventId);
  }, [dispatch]);

  return (
    <>
      {isMaintenance ? (
        <Maintenance />
      ) : (
        <Body amidaEventState={amidaEventState} />
      )}
    </>
  );
};

export default CreateCompletePage;
