import TextField from "@mui/material/TextField/TextField";
import { CSSProperties } from "react";

type Props = {
  id: string;
  state: number;
  setState: React.Dispatch<React.SetStateAction<number>>;
  label?: string;
  variant: "outlined" | undefined;
  style?: CSSProperties;
  required?: boolean;
  fullWidth?: boolean;
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement> | undefined;
};

const NumericField = (props: Props) => {
  const onChangeHandle = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const result = Math.abs(Number(e.target.value)).toString();

    if (result === "NaN") {
      props.setState(2);
    } else {
      props.setState(Number(result));
    }
  };

  return (
    <TextField
      id="max-joined-users-num-input"
      fullWidth={props.fullWidth}
      required={props.required}
      value={props.state}
      onChange={onChangeHandle}
      label={props.label}
      variant={props.variant}
      style={props.style}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
        min: 2,
        max: 499,
        step: 1,
        enterKeyHint: "done",
      }}
      onKeyDown={props.onKeyDown}
    />
  );
};

export default NumericField;
