import {
  Box as MuiBox,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Fade,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AmidaEventState } from "../../../domain/types/Event";
import { FetchStatus } from "../../../domain/types/FetchStatus";
import NotFound from "../../common/NotFound";
import DoneIcon from "@mui/icons-material/Done";

type bodyProps = {
  amidaEventState: AmidaEventState;
};

const Body = (props: bodyProps) => {
  const amidaEventState = props.amidaEventState;
  const amidaEvent = amidaEventState.amidaEvent;
  const status = amidaEventState.status;
  const navigate = useNavigate();

  const [showDoneIcon, setShowDoneIcon] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDoneIcon(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  switch (status) {
    case FetchStatus.SUCCEEDED:
      return (
        <Paper>
          <MuiBox
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h6">さくせい完了</Typography>
            <MuiBox component="div" m={0.5} />
            {showDoneIcon ? (
              <Fade in={showDoneIcon} timeout={777}>
                <DoneIcon color="secondary" fontSize="large" />
              </Fade>
            ) : (
              <CircularProgress size={35} />
            )}
          </MuiBox>
          <Typography variant="body1">
            あみだくじの作成に成功しました！
          </Typography>
          <MuiBox m={2} component="div" />
          <Typography variant="body2">
            名前: {amidaEvent.title?.val}
            <br />
            最大参加人数: {amidaEvent.maxJoinedUsersNum}
            <br />
            ひとりあたりの横棒の本数: {amidaEvent.bridgeNumRange?.max}
            <br />
            振り分け:{" "}
            {amidaEvent.playerLabels.length !== 0
              ? amidaEvent.playerLabels
                  .map((label) => label.labelName)
                  .join(", ")
              : "数字 (1, 2, 3, ...)"}
          </Typography>

          <MuiBox m={2} component="div" />
          <Typography variant="body1">
            次の画面で、2人以上の参加者を招待して、あみだくじを開始しましょう。
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => navigate("/e/" + amidaEventState.amidaEvent.id)}
          >
            <Typography variant="body1">あみだくじに移動する</Typography>
          </Button>
        </Paper>
      );
    case FetchStatus.FAILED:
      return <NotFound />;
    default:
      return (
        <MuiBox textAlign="center" component="div">
          <CircularProgress />
        </MuiBox>
      );
  }
};

export default Body;
