import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box as MuiBox,
} from "@mui/material";
import React, { useState } from "react";

type Props = {
  rowsToShow: { userId: string; name: string; role: string }[];
  isShowAllRows: boolean;
  onClickShowMore: () => void;
};

const ResultTable = (props: Props) => {
  const { rowsToShow, isShowAllRows, onClickShowMore } = props;

  const [isShowResultTable, setIsShowResultTable] = useState(false);

  return (
    <>
      {!isShowResultTable ? (
        <MuiBox
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsShowResultTable(true)}
          >
            表で見る
          </Button>
        </MuiBox>
      ) : (
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>参加者</TableCell>
                <TableCell>振り分け</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsToShow.map((row) => (
                <TableRow
                  key={row.userId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.role}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <MuiBox
            component="div"
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsShowResultTable(false)}
            >
              閉じる
            </Button>

            <MuiBox component="div" m={1} />

            {isShowAllRows ? null : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onClickShowMore()}
              >
                さらに表示
              </Button>
            )}
          </MuiBox>
        </TableContainer>
      )}
    </>
  );
};

export default ResultTable;
