import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User, UserState } from "../types/User";
import axios from "axios";
import { apiUrl } from "../../application/config/env";
import { FetchStatus } from "../types/FetchStatus";

export const createUser = createAsyncThunk<{ user: User }, string>(
    "users/",
    async (name, { rejectWithValue }) => {
        try {
            const data = { "name": name };
            const response = await axios.post(`${apiUrl}/users`, data, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response);
        }
    }
);

export const getMyUser = createAsyncThunk<{ user: User }>(
    "users/my_user",
    async (_, { rejectWithValue }) => {
        // cookie がない場合は、リクエストを送らない
        if (!document.cookie) {
            return rejectWithValue({ message: "cookie not found" });
        }
        try {
            const response = await axios.get(`${apiUrl}/users/my_user`, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getUser = createAsyncThunk<{ user: User }, string>(
    "users/:id",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/users/${id}`, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getAdminEvents = createAsyncThunk<{ events: any[] }, string>(
    "users/:id/admin_events",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/users/${id}/admin_events`, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getJoinedEvents = createAsyncThunk<{ events: any[] }, string>(
    "users/:id/joined_events",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/users/${id}/joined_events`, { withCredentials: true });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    status: FetchStatus.IDOL,
    user: {
        id: undefined,
        name: undefined,
        adminEvents: null,
        joinedEvents: null,
    } as User,
} as UserState;

const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        foo: (state, action) => {
            console.log(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createUser.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.user = action.payload.user;
            // console.log(action.payload);
        });
        builder.addCase(createUser.rejected, (state, action) => {
        });
        builder.addCase(getMyUser.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.user = action.payload.user;
            // state.name = action.payload.user.name;
            // state.id = action.payload.user.id;
            // state.adminEvents = action.payload.user.adminEvents;
            // state.participantEvents = action.payload.user.participantEvents;
        });
        builder.addCase(getMyUser.rejected, (state, action) => {
            state.status = FetchStatus.FAILED;
        });
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.user = action.payload.user;
        });
        builder.addCase(getUser.rejected, (state, action) => {
            state.status = FetchStatus.FAILED;
        });
        builder.addCase(getAdminEvents.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.user.adminEvents = action.payload.events;
        });
        builder.addCase(getAdminEvents.rejected, (state, action) => {
            state.status = FetchStatus.FAILED;
        });
        builder.addCase(getJoinedEvents.fulfilled, (state, action) => {
            state.status = FetchStatus.SUCCEEDED;
            state.user.joinedEvents = action.payload.events;
        });
        builder.addCase(getJoinedEvents.rejected, (state, action) => {
            state.status = FetchStatus.FAILED;
        });
    },
});


export const { foo } = userSlice.actions;
export default userSlice.reducer;
