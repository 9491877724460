import React, { useRef } from "react";
import { Typography, TextField, Box as MuiBox, Slider } from "@mui/material";
import PlayerLabelsForm from "./PlayerLabelsForm";
import BridgePreview from "./BridgePreview";
import NumericField from "./NumericField";

type eventFormProps = {
  setEventTitle: React.Dispatch<React.SetStateAction<string>>;
  setBridgeNumRangeMin: React.Dispatch<React.SetStateAction<number>>;
  setBridgeNumRangeMax: React.Dispatch<React.SetStateAction<number>>;
  setPlayerLabelWithErrors: React.Dispatch<
    React.SetStateAction<{ label: string; error: boolean }[]>
  >;
  eventTitle: string;
  bridgeNumRangeMin: number;
  bridgeNumRangeMax: number;
  playerLabelWithErrors: { label: string; error: boolean }[];
  maxJoinedUsersNum: number;
  setMaxJoinedUsersNum: React.Dispatch<React.SetStateAction<number>>;
};

const EventForm = (props: eventFormProps) => {
  const {
    setEventTitle,
    setBridgeNumRangeMin,
    setBridgeNumRangeMax,
    setPlayerLabelWithErrors,
    eventTitle,
    // bridgeNumRangeMin,
    bridgeNumRangeMax,
    playerLabelWithErrors,
    maxJoinedUsersNum,
    setMaxJoinedUsersNum,
  } = props;
  //最低限も決められる機能は不要なのでコメントアウト
  // const handleBridgeNumRangeChange = (
  //   event: any,
  //   newValue: number | number[]
  // ) => {
  //   if (typeof newValue === "number") {
  //     return;
  //   }
  //   setBridgeNumRangeMin(newValue[0]);
  //   setBridgeNumRangeMax(newValue[1]);
  // };
  const handleBridgeNumRangeChange = (
    event: any,
    newValue: number | number[]
  ) => {
    if (typeof newValue !== "number") {
      return;
    }
    setBridgeNumRangeMin(2);
    setBridgeNumRangeMax(newValue);
  };

  const textFieldRef = useRef<HTMLInputElement>(null);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // デフォルトの動作を防止
      textFieldRef.current?.blur(); // フォーカスを解除
    }
  };

  return (
    <>
      <MuiBox component="div">
        <Typography variant="h6">あみだくじ名</Typography>
        <TextField
          id="outlined-basic"
          label="ここに入力（例: 幹事決め）"
          fullWidth
          variant="outlined"
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
          inputRef={textFieldRef}
          onKeyDown={handleKeyDown}
          inputProps={{
            enterKeyHint: "done",
            type: "text",
          }}
        />
      </MuiBox>

      <MuiBox m={2} component="div" />

      <MuiBox component="div">
        <Typography variant="h6">最大参加人数</Typography>
        {/* <Typography variant="body1" color="textSecondary">
          あみだくじの最大参加人数を設定できます
        </Typography> */}
        <NumericField
          id="max-joined-users-num-input"
          state={maxJoinedUsersNum}
          setState={setMaxJoinedUsersNum}
          label="最大参加人数"
          variant="outlined"
          style={{ width: "200px" }}
          required
          fullWidth
          onKeyDown={handleKeyDown}
        />
      </MuiBox>

      <MuiBox m={2} component="div" />

      <PlayerLabelsForm
        playerLabelWithErrors={playerLabelWithErrors}
        setPlayerLabelWithErrors={setPlayerLabelWithErrors}
      />

      <MuiBox m={2} component="div" />

      <MuiBox component="div">
        <Typography variant="h6">横棒の数</Typography>
        <BridgePreview bridgeNumRangeMax={bridgeNumRangeMax} />
        <Typography variant="body1" color="textSecondary">
          ひとりの参加者が伸ばす横棒の数を設定できます
        </Typography>
        <Slider
          color="secondary"
          getAriaLabel={() => "横棒の本数"}
          //最低限も決められる機能は不要なのでコメントアウト
          // value={[bridgeNumRangeMin, bridgeNumRangeMax]}
          value={bridgeNumRangeMax}
          min={2}
          max={5}
          onChange={handleBridgeNumRangeChange}
          valueLabelDisplay="auto"
          marks={[
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
          ]}
        />
      </MuiBox>
    </>
  );
};

export default EventForm;
