import {
  Paper,
  Typography,
  Box as MuiBox,
  Button,
  Collapse,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";

const AboutAmidasanForJoin = () => {
  const [open, setOpen] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false); // 画像のローディング状態を管理

  const handleToggle = () => {
    setOpen(!open);
    if (!imgLoaded) {
      setImgLoaded(true); // 初めて開いたときに画像をローディング
    }
  };

  return (
    <Paper>
      <Typography variant="h6">あみださん</Typography>
      <Typography variant="body1" color="textSecondary">
        参加型のあみだくじサイトです。みんなの棒をつないで、あみだくじを完成させます。
      </Typography>
      <Button
        onClick={handleToggle}
        fullWidth
        variant="contained"
        color="secondary"
        sx={{ padding: 0 }}
      >
        {open ? (
          <Typography>説明を閉じる △</Typography>
        ) : (
          <Typography>説明を開く ▽</Typography>
        )}
      </Button>
      <Collapse in={open}>
        <MuiBox
          component="div"
          sx={{
            width: "100%",
            borderRadius: "15px",
            MuiBoxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
            overflow: "hidden", // 画像の角を丸めるためにオーバーフローを隠す
            marginTop: "20px", // 余白を追加
            aspectRatio: "16/9", // アスペクト比を設定
          }}
        >
          {imgLoaded ? ( // 画像がローディングされた場合のみ表示
            <img
              src="/images/amidasan-gif.gif"
              alt="あみださん"
              style={{ width: "100%", height: "100%" }}
              loading="lazy"
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              <CircularProgress />
            </Typography>
          )}
        </MuiBox>

        <Typography variant="body1">参加の仕方は、3 ステップ！</Typography>
        <Typography variant="body2" color="textSecondary">
          1. 左右に棒を伸ばす
          <br />
          2. あなたの名前を入力
          <br />
          3.「参加する」ボタンを押す
          <br />
          あとは、あみだの開始を待ちましょう！
        </Typography>
      </Collapse>
    </Paper>
  );
};

export default AboutAmidasanForJoin;
