import { useEffect, useState } from "react";
import { User } from "../../domain/types/User";
import { AmidaEvent, AmidaEventStatus } from "../../domain/types/Event";
import { Player } from "../../domain/types/Player";


const useEventPageSuccessClosed = (amidaEvent: AmidaEvent, user: User) => {

    const [playersSortedByStartIndex, setPlayersSortedByStartIndex] = useState<
        Player[]
    >([]);
    const [myPlayerIndexOfPlayersSortedByStartIndex, setMyPlayerIndexOfPlayersSrotedByStartIndex] = useState<number | null>(null);
    const [playersSortedByGoalIndex, setPlayersSortedByGoalIndex] = useState<
        Player[]
    >([]);
    const [myPlayerIndexOfPlayersSortedByGoalIndex, setMyPlayerIndexOfPlayersSortedByGoalIndex] = useState<number | null>(null);

    useEffect(() => {
        if (amidaEvent.status !== AmidaEventStatus.CLOSED) {
            return;
        }
        const players = amidaEvent.players;
        const playersSortedByStartIndex = [...players].sort((a, b) => {
            const aStartIndex = a.ball?.startIndex;
            const bStartIndex = b.ball?.startIndex;
            if (aStartIndex === undefined || bStartIndex === undefined) {
                return 0;
            }
            return aStartIndex < bStartIndex ? -1 : 1;
        });
        setPlayersSortedByStartIndex(playersSortedByStartIndex);
        const playersSortedByGoalIndex = [...players].sort((a, b) => {
            const aGoalIndex = a.ball?.goalIndex;
            const bGoalIndex = b.ball?.goalIndex;
            if (aGoalIndex === undefined || bGoalIndex === undefined) {
                return 0;
            }
            return aGoalIndex < bGoalIndex ? -1 : 1;
        }
        );
        setPlayersSortedByGoalIndex(playersSortedByGoalIndex);
        const myPlayerIndexStart = playersSortedByStartIndex.findIndex((p) => p.userId === user.id);
        setMyPlayerIndexOfPlayersSrotedByStartIndex(myPlayerIndexStart);
        const myPlayerIndexGoal = playersSortedByGoalIndex.findIndex((p) => p.userId === user.id);
        setMyPlayerIndexOfPlayersSortedByGoalIndex(myPlayerIndexGoal);
    }, [amidaEvent.players, amidaEvent.status, user.id]);

    const [playerLabels, setPlayerLabels] = useState<string[]>([]);
    useEffect(() => {
        const val = amidaEvent.playerLabels.map((pl) => pl.labelName);
        setPlayerLabels(val);
    }, [amidaEvent.playerLabels]);


    const [rows, setRows] = useState(
        [createRowData("", "", "")],
    );
    useEffect(() => {
        const rows = playersSortedByGoalIndex.map((player, index) => {
            if (player.userId === undefined || player.displayName?.val === undefined) {
                const randomKey = Math.random().toString(32).substring(2);
                return createRowData(randomKey, "名前がありません", "振り分けがありません");
            }
            if (index >= playerLabels.length) {
                return createRowData(player.userId, player.displayName.val, "振り分けがありません");
            }
            return createRowData(player.userId, player.displayName.val, playerLabels[index]);
        });

        //自分を先頭に持ってくる
        if (myPlayerIndexOfPlayersSortedByGoalIndex !== null && myPlayerIndexOfPlayersSortedByGoalIndex !== -1) {
            const myRow = rows[myPlayerIndexOfPlayersSortedByGoalIndex];
            rows.splice(myPlayerIndexOfPlayersSortedByGoalIndex, 1);
            rows.unshift(myRow);
        }

        setRows(rows);
    }, [playersSortedByGoalIndex, playerLabels, myPlayerIndexOfPlayersSortedByGoalIndex]);

    const [rowsToShowMax, setRowsToShowMax] = useState(5);
    const [rowsToShow, setRowsToShow] = useState(rows.slice(0, rowsToShowMax));
    const [isShowAllRows, setIsShowAllRows] = useState(false);

    const onClickShowMore = () => {
        setRowsToShowMax(rowsToShowMax + 5);
    };

    useEffect(() => {
        setRowsToShow(rows.slice(0, rowsToShowMax));
        if (rows.length <= rowsToShowMax) {
            setIsShowAllRows(true);
        } else {
            setIsShowAllRows(false);
        }
    }, [rows, rowsToShowMax]);

    return {
        playersSortedByStartIndex,
        myPlayerIndex: myPlayerIndexOfPlayersSortedByStartIndex,
        playerLabels,
        playersSortedByGoalIndex,
        rowsToShow,
        isShowAllRows,
        onClickShowMore,
    };
};

const createRowData = (userId: string, name: string, role: string) => {
    return { userId, name, role };
}

export default useEventPageSuccessClosed;
