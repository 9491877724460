import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { store } from "../../application/state/store";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import {
  Container,
  CssBaseline,
  ThemeProvider,
  Box as MuiBox,
} from "@mui/material";
import darkTheme from "../themes/darkTheme";
import Footer from "../common/Footer";
import Header from "../common/Header";
import lightTheme from "../themes/lightTheme";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    setIsDarkMode(darkModeMediaQuery.matches);

    const handleChange = (e: MediaQueryListEvent) => {
      setIsDarkMode(e.matches);
    };

    darkModeMediaQuery.addEventListener("change", handleChange);

    return () => {
      darkModeMediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <MuiBox
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <BrowserRouter>
            <Header />
            <Container maxWidth="md">
              <Router />
            </Container>
            <Footer />
          </BrowserRouter>
        </MuiBox>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
