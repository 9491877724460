import React from "react";
import { User } from "../../../domain/types/User";
import { AmidaEvent, AmidaEventStatus } from "../../../domain/types/Event";

import SuccessOpen from "./SuccessOpen";
import SuccessClosed from "./SuccessClosed";
import NotFound from "../../common/NotFound";

type Props = {
  amidaEvent: AmidaEvent;
  amidaEventTimestamp: string;
  user: User;
};

const Success = (props: Props) => {
  const { amidaEvent, amidaEventTimestamp, user } = props;

  switch (amidaEvent.status) {
    case AmidaEventStatus.WAITING:
    case AmidaEventStatus.OPEN:
      return (
        <SuccessOpen
          amidaEvent={amidaEvent}
          amidaEventTimestamp={amidaEventTimestamp}
          user={user}
        />
      );
    case AmidaEventStatus.CLOSED:
      return <SuccessClosed amidaEvent={amidaEvent} user={user} />;
    default:
      return <NotFound />;
  }
};

export default Success;
