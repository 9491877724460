import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import CreateCompletePage from "../pages/CreateCompletePage";
import EventPage from "../pages/EventPage";
import UserPage from "../pages/UserPage";
import EditPage from "../pages/EditPage";
import TermsPage from "../pages/TermsPage";
import PrivacyPage from "../pages/PrivacyPage";
import FaqPage from "../pages/FaqPage";

const Router = () => {
  return (
    <Routes>
      <Route path={`/*`} element={<HomePage />} />
      <Route path={`/create_complete`} element={<CreateCompletePage />} />
      <Route path={`/e/:eventId`} element={<EventPage />} />
      <Route path={`/e/:eventId/edit`} element={<EditPage />} />

      <Route path={`/u/:userId`} element={<UserPage />} />

      <Route path={`/terms`} element={<TermsPage />} />
      <Route path={`/privacy`} element={<PrivacyPage />} />
      <Route path={`/faq`} element={<FaqPage />} />
    </Routes>
  );
};

export default Router;
