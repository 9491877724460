import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../../domain/features/UserSlice";
import eventReducer from "../../domain/features/EventSlice";


export const store = configureStore({
    reducer: {
        user: userReducer,
        event: eventReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
