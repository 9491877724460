import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { User } from "../../domain/types/User";
import {
    updateEvent,
} from "../../domain/features/EventSlice";
import { AmidaEvent, } from "../../domain/types/Event";
import { useNavigate } from "react-router-dom";


const useEditPageSuccess = (amidaEvent: AmidaEvent, user: User) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [eventTitle, setEventTitle] = useState<string>("");
    useEffect(() => {
        if (amidaEvent.title === undefined) {
            return;
        }
        setEventTitle(amidaEvent.title.val);
    }, [amidaEvent.title]);

    const [bridgeNumRangeMin, setBridgeNumRangeMin] = useState<number>(0);
    const [bridgeNumRangeMax, setBridgeNumRangeMax] = useState<number>(0);
    useEffect(() => {
        if (amidaEvent.bridgeNumRange === undefined) {
            return;
        }
        setBridgeNumRangeMin(amidaEvent.bridgeNumRange.min);
        setBridgeNumRangeMax(amidaEvent.bridgeNumRange.max);
    }, [amidaEvent.bridgeNumRange]);

    const [playerLabelWithErrors, setPlayerLabelWithErrors] = useState<
        {
            label: string;
            error: boolean;
        }[]
    >([]);
    useEffect(() => {
        const val = amidaEvent.playerLabels.map((pl) => {
            return { label: pl.labelName, error: pl.labelName === "" };
        });
        setPlayerLabelWithErrors(val);
    }, [amidaEvent.playerLabels]);

    const [inputPassword, setInputPassword] = useState<string>("");

    const [maxJoinedUsersNum, setMaxJoinedUsersNum] = useState<number>(10);
    useEffect(() => {
        if (amidaEvent.maxJoinedUsersNum === undefined) {
            return;
        }
        setMaxJoinedUsersNum(amidaEvent.maxJoinedUsersNum);
    }, [amidaEvent.maxJoinedUsersNum]);

    const handleUpdateEvent = async () => {
        if (!eventTitle) {
            alert("あみだくじ名を入力してください");
            return;
        }

        if (eventTitle.length > 40) {
            alert("あみだくじ名は40文字以内で入力してください");
            return;
        }

        // playerLabelsに空文字が含まれている場合はエラー
        const hasError = playerLabelWithErrors.some(
            (playerLabelWithError) => playerLabelWithError.error
        );
        if (hasError) {
            alert("振り分け名を短くしてください");
            return;
        }

        if (maxJoinedUsersNum < 2) {
            alert("最大参加人数は2人以上にしてください");
            return;
        }
        if (maxJoinedUsersNum > 499) {
            alert("最大参加人数は499人以下にしてください");
            return;
        }

        try {
            const eventId = amidaEvent.id;
            if (!eventId) {
                throw new Error();
            }

            const adminUserId = user.id;
            if (!adminUserId) {
                throw new Error();
            }
            // あみだくじ更新処理
            const resEvent = await dispatch(
                updateEvent({
                    eventId: eventId,
                    adminUserId: adminUserId,
                    title: eventTitle,
                    bridgeNumRangeMin: bridgeNumRangeMin,
                    bridgeNumRangeMax: bridgeNumRangeMax,
                    playerLabels: playerLabelWithErrors
                        .map((playerLabelWithError) => playerLabelWithError.label)
                        .filter((label) => label !== ""),
                    maxJoinedUsersNum: maxJoinedUsersNum,
                    password: inputPassword,
                }) as any
            );
            if (!resEvent.payload.event.id) {
                throw new Error();
            }
            navigate("/e/" + amidaEvent.id);

        } catch (e) {
            alert("あみだくじ更新に失敗しました");
        }
    };




    return {

        eventTitle,
        setEventTitle,
        bridgeNumRangeMin,
        setBridgeNumRangeMin,
        bridgeNumRangeMax,
        setBridgeNumRangeMax,
        playerLabelWithErrors,
        setPlayerLabelWithErrors,
        maxJoinedUsersNum,
        setMaxJoinedUsersNum,
        updateEvent: handleUpdateEvent,
        inputPassword,
        setInputPassword,
    };
};

export default useEditPageSuccess;
