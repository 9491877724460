import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "../../domain/types/User";
import { getMyUser } from "../../domain/features/UserSlice";
import { getEvent } from "../../domain/features/EventSlice";
import { AmidaEventState, AmidaEventStatus } from "../../domain/types/Event";
import Body from "../components/EventPage/Body";
import { useParams } from "react-router-dom";
import { isMaintenance } from "../../application/config/env";
import Maintenance from "../common/Maintenance";
import useInterval from "../hooks/useInterval";

const EventPage = () => {
  const userState = useSelector((state: { user: UserState }) => state.user);

  const amidaEventState = useSelector(
    (state: { event: AmidaEventState }) => state.event
  );
  const dispatch = useDispatch();
  const { eventId } = useParams<{ eventId: string }>();

  useEffect(() => {
    if (eventId == null) {
      return;
    }
    dispatch(getMyUser() as any);
    dispatch(getEvent(eventId) as any);
  }, [dispatch, eventId]);

  //ポーリングを管理するためのstate
  const [pollingInterval, setPollingInterval] = useState(5000); // 初期間隔を5秒に設定
  const [pollingActive, setPollingActive] = useState(true); // ポーリングの状態を管理

  //ポーリングの制御
  useEffect(() => {
    var timer: string | number | NodeJS.Timeout | null | undefined = null;
    if (amidaEventState.amidaEvent.status === AmidaEventStatus.CLOSED) {
      setPollingActive(false); // event.statusがclosedのときポーリングを停止
    } else if (amidaEventState.isEditing) {
      setPollingActive(false); // 編集中はポーリングを停止
      // 3分後にポーリングを再開
      timer = setTimeout(() => {
        // getEvent を実行することで、isEditingがfalseになる
        if (eventId) dispatch(getEvent(eventId) as any);
      }, 180000);
    } else {
      setPollingActive(true); // 編集していない場合はポーリングを再開
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [
    amidaEventState.amidaEvent.status,
    amidaEventState.isEditing,
    dispatch,
    eventId,
    pollingActive,
  ]);

  //ポーリング間隔を変更する処理
  useEffect(() => {
    const timer1 = setTimeout(() => {
      setPollingInterval(7000); // 3分後に間隔を7秒に変更
    }, 180000); // 3分後

    const timer2 = setTimeout(() => {
      setPollingInterval(10000); // 5分後に間隔を10秒に変更
    }, 300000); // 5分後

    const timer3 = setTimeout(() => {
      setPollingInterval(20000); // 10分後に間隔を20秒に変更
    }, 600000); // 10分後

    // 15分後にポーリングを恒久的に停止
    const stopPollingTimer = setTimeout(() => {
      setPollingActive(false);
      setPollingInterval(0);
    }, 900000); // 15分後

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(stopPollingTimer);
    }; // クリーンアップ
  }, []);

  //ポーリング処理
  useInterval(
    () => {
      if (eventId && pollingActive) {
        dispatch(getEvent(eventId) as any);
      }
    },
    pollingActive ? pollingInterval : null
  ); // pollingActiveがfalseのときはnullを設定

  return (
    <>
      {isMaintenance ? (
        <Maintenance />
      ) : (
        <Body {...{ amidaEventState, userState }} />
      )}
    </>
  );
};

export default EventPage;
