import React from "react";
import ScrollToTop from "../common/ScrollToTop";
import { Paper } from "@mui/material";
import JsonContent from "../common/JsonContent";

const FaqPage: React.FC = () => {
  return (
    <>
      <ScrollToTop />

      <Paper>
        <JsonContent jsonFilePath="/data/faq.json" />
      </Paper>
    </>
  );
};

export default FaqPage;
