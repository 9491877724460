import { useFrame } from "@react-three/fiber";
import { useMemo, useRef } from "react";
import { AdditiveBlending, Points, Color } from "three";

const Fireworks = () => {
  const particles = useMemo(() => {
    const count = 1000; // Increase particle count for more density
    const positions = new Float32Array(count * 3);
    const colors = new Float32Array(count * 3);
    for (let i = 0; i < count; i++) {
      positions.set(
        [
          (Math.random() - 0.5) * 20,
          (Math.random() - 0.5) * 20,
          (Math.random() - 0.5) * 20,
        ],
        i * 3
      );
      const color = new Color();
      color.setHSL(Math.random(), 1.0, 0.5);
      colors.set([color.r, color.g, color.b], i * 3);
    }
    return { positions, colors };
  }, []);

  const ref = useRef();
  useFrame(({ clock }) => {
    if (ref.current) {
      const time = clock.getElapsedTime();
      (ref.current as any).rotation.y += 0.0005; // Slow down rotation
      (ref.current as any).rotation.x += 0.00025; // Slow down rotation

      // Update positions to create upward motion
      const positions = (ref.current as any).geometry.attributes.position.array;
      for (let i = 0; i < positions.length; i += 3) {
        positions[i + 1] += 0.005 * Math.sin(time + i * 0.01); // Gentle upward motion
      }
      (ref.current as any).geometry.attributes.position.needsUpdate = true;
    }
  });

  return (
    <points ref={ref as unknown as React.RefObject<Points>}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          array={particles.positions}
          count={particles.positions.length / 3}
          itemSize={3}
        />
        <bufferAttribute
          attach="attributes-color"
          array={particles.colors}
          count={particles.colors.length / 3}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        attach="material"
        size={0.1} // Decrease size for subtle effect
        vertexColors
        blending={AdditiveBlending}
        transparent
      />
    </points>
  );
};

export default Fireworks;
