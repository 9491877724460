import { Box as MuiBox, CircularProgress } from "@mui/material";
import { AmidaEventState } from "../../../domain/types/Event";
import { FetchStatus } from "../../../domain/types/FetchStatus";
import { UserState } from "../../../domain/types/User";

import NotFound from "../../common/NotFound";
import Success from "./Success";

type Props = {
  amidaEventState: AmidaEventState;
  userState: UserState;
};

const Body = (props: Props) => {
  const { amidaEventState, userState } = props;
  const amidaEventStatus = amidaEventState.status;
  const amidaEvent = amidaEventState.amidaEvent;
  const amidaEventTimestamp = amidaEventState.timestamp;
  const user = userState.user;

  switch (amidaEventStatus) {
    case FetchStatus.SUCCEEDED:
      return (
        <Success
          amidaEvent={amidaEvent}
          amidaEventTimestamp={amidaEventTimestamp}
          user={user}
        />
      );
    case FetchStatus.FAILED:
      return <NotFound />;
    default:
      return (
        <MuiBox component="div" textAlign="center">
          <CircularProgress />
        </MuiBox>
      );
  }
};

export default Body;
