import React from "react";
import { UserState } from "../../../domain/types/User";
import { FetchStatus } from "../../../domain/types/FetchStatus";
import Success from "./Success";
import { CircularProgress, Box as MuiBox } from "@mui/material";
import NotFound from "../../common/NotFound";

type bodyProps = {
  userState: UserState;
};

const Body = (props: bodyProps) => {
  const { userState } = props;
  const user = userState.user;

  switch (userState.status) {
    case FetchStatus.IDOL:
      return (
        <MuiBox component="div" textAlign="center">
          <CircularProgress />
        </MuiBox>
      );
    case FetchStatus.SUCCEEDED:
      return <Success user={user} />;
    case FetchStatus.FAILED:
      return <NotFound />;
    default:
      return (
        <MuiBox component="div" textAlign="center">
          <CircularProgress />
        </MuiBox>
      );
  }
};

export default Body;
